body {
    overflow-y: scroll !important;
}


.modal900 {
    width: 900px !important;
    z-index:9999 !important;
}



.modal-dialog {
    max-width:900px !important;
}

.ProdutosCadastro select {
    height: 36px !important;
}


.ProdutosCadastro .btngrp button {
    height: 36px !important;
}


.ProdutosCadastro .images-container {
    display: flex;
    flex-flow: wrap;
    justify-items: center;
    width: 100% !important;
}

.ProdutosCadastro .foto img {
    width: 100px !important;
    height: 100px !important;
    border: 4px solid #E0E0E0;
    margin: 3px;
}

.ProdutosCadastro .foto {
    margin-bottom: 20px;
}
