.ProdutosLista .table tr {
    cursor: pointer;
}

.ProdutosLista #btnBusca {
    height: 35px;
}

.ProdutosLista .risk {
    text-decoration: line-through;
}

.ProdutosLista #categoria_id, .ProdutosLista #destaque {
    height: 35px;
}


.ProdutosLista td {
    cursor: pointer;
}


.ProdutosLista .checkbox {
    width: 20px !important;
    height: 20px !important;
}
