.ClientesLista .table tr {
    cursor: pointer;
}

.ClientesLista #btnBusca {
    height: 35px;
}

.ClientesLista .risk {
    text-decoration: line-through;
}

.ClientesLista #ativo {
    height: 35px;
}


.ClientesLista td {
    cursor: pointer;
}


.ClientesLista .checkbox {
    width: 20px !important;
    height: 20px !important;
}
