.BannersLista .checkbox {
    width: 20px !important;
    height: 20px !important;
}


.BannersLista .risk {
    text-decoration: line-through;
}


.BannersLista td {
    cursor: pointer;
}
